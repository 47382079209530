<template>
  <Toast />

  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '450px' }"
    header="FECHA VENCIMIENTO"
    class="p-fluid"
    :modal="true"
    @hide="closeModal"
  >
    <div class="p-field">
      <label for="producto"
        >Producto : <strong> {{ producto_fechas.descripcion }} </strong></label
      >
    </div>
    <div class="p-field">
      <label for="cantidad"
        >Cantidad :
        <strong> {{ producto_fechas.pivot.cantidad }} </strong></label
      >
    </div>
    <DataTable
      ref="dtFechaVence"
      :value="producto_fechas.fechas_vencimiento"
      editMode="cell"
      @cell-edit-complete="onChangeEditandoCelda"
      class="editable-cells-table p-datatable-sm col-12"
    >
      <Column class="text-right" field="cantidad" header="CANTIDAD">
        <template #body="slotProps">
          {{ convertirNumeroGermanicFormat(slotProps.data.cantidad) }}
        </template>
        <template #editor="{ data }">
          <InputNumber
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            locale="de-ED"
            v-model="data.cantidad"
            autofocus
          />
        </template>
      </Column>
      <Column
        class="text-center"
        field="fecha_vence"
        header="FECHA VENCIMIENTO"
      >
        <template #body="slotProps">
          {{ slotProps.data.fecha_vence.toLocaleDateString() }}
        </template>
        <template #editor="{ data }">
          <Calendar
            v-model="data.fecha_vence"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          />
        </template>
      </Column>
      <Column class="text-right" field="lote" header="LOTE">
        <template #body="slotProps">
          {{ slotProps.data.lote }}
        </template>
        <template #editor="{ data }">
          <InputText v-model="data.lote" />
        </template>
      </Column>
      <Column>
        <template #header>
          <Button
            class="p-button-rounded p-button-text"
            icon="pi pi-plus"
            v-tooltip.top="'Agregar nueva fecha'"
            @click="agregarFechaVence"
          />
        </template>
        <template #body="data">
          <Button
            class="p-button-rounded p-button-text"
            icon="pi pi-trash"
            v-tooltip.top="'Quitar fecha'"
            @click="eliminarFechaVence(data)"
          />
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="registrarFechaVence"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
export default {
  name: "FechaVence",
  emits: ["closeModal"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      enviado: false,
      activarModal: this.show,
      producto_fechas: this.producto,
    };
  },
  methods: {
    eliminarFechaVence(data) {
      this.$confirm.require({
        message: `¿Está seguro de quitar la fecha?`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.producto_fechas.fechas_vencimiento.splice(data.index, 1);
        },
      });
    },
    onChangeEditandoCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
			this.verificarCantidad();
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    registrarFechaVence() {
      this.enviado = true;
      if (this.verificarCantidad) {
        this.$toast.add({
          severity: "success",
          summary: "Éxito",
          detail: "Se registró las fechas de vencimiento",
          life: 3000,
        });
				this.closeModal();
				this.enviado = false;
      }
    },
    verificarCantidad() {
      let cantidad = 0;
      this.producto_fechas.fechas_vencimiento.forEach((item) => {
        cantidad += item.cantidad;
      });
      this.producto_fechas.fechas_vencimiento.forEach((item) => {
        if (item.cantidad <= 0) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad debe ser mayor a 0",
            life: 3000,
          });
          return false;
        }
      });
      if (cantidad != this.producto_fechas.pivot.cantidad) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "La cantidad no coincide con la cantidades de fechas",
          life: 3000,
        });
        return false;
      }
    },
    agregarFechaVence() {
      this.producto_fechas.fechas_vencimiento.push({
        cantidad: 1,
        fecha_vence: new Date(),
        lote: null,
      });
    },
  },
  watch: {
    show(value) {
      this.activarModal = value;
    },
    producto(value) {
      this.producto_fechas = value;
    },
  },
};
</script>